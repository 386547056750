<template>
<div id="team" class="pb-4" :class="{'head-box': isBg, 'head-box1': !isBg}">
    <div id="head" class="d-flex align-items-center ps-3">
        <div class="mx-2">
            <img class="rounded-circle" :src="data.avatar" />
        </div>
        <div class="d-flex flex-column justify-content-center">
            <span class="title">{{ data.name }}</span>
            <p class="border border-white text-center radius-10 text-white fs-7 my-1">{{ data.level.name }}</p>
        </div>
        <!-- <div class="me-auto qrc-btn col d-flex justify-content-end pe-4" @click="genCode">
            <a id="qrcode">邀请好友</a>
        </div> -->
    </div>
    <div v-show="showCode" class="wrap" @click="showCode = false">
        <img :src="qcode" />
    </div>
    <div class="row mx-2">
        <div class="text-center col fs-8 px-1" v-if="70">
            <p class="mb-1">{{ data.counts.today }}</p>
            <p class="mb-1">今日新增</p>
        </div>
        <div class="text-center col fs-8 px-1" v-if="60">
            <p class="mb-1">{{ data.counts.yesterday }}</p>
            <p class="mb-1">昨日 新增</p>
        </div>
        <div class="text-center col fs-8 px-1" v-if="88">
            <p class="mb-1">{{ data.counts.this_month }}</p>
            <p class="mb-1">本月新增</p>
        </div>
        <div class="text-center col fs-8 px-1" v-if="200">
            <p class="mb-1">{{ data.counts.team_peoples }}</p>
            <p class="mb-1">团队人数</p>
        </div>
    </div>
</div>
<div class="content bg-white radius-top">
    <h4>直邀会员</h4>
    <p class="my-1 fs-9 text-muted mb-3">直邀会员<span class="text-danger fw-bold">{{ data.counts.direct }}</span>人，实名会员<span class="text-danger fw-bold">{{ data.counts.real_name }}</span>人</p>
    <List v-if="readyList" :data="data.teams" @changed="getData"></List>
</div>

</template>

<script setup>

import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import QRCode from 'qrcode'
// import { getCookie } from '@/lib/unitls'
import List from '@/components/distribution/team/list.vue'

const route = useRoute()
const readyList = ref(false)
const isBg = ref(true)
const data = ref({
    id: 100, //会员Id
    name: '陈小春', //姓名
    phone: '180180180190', //电话
    avatar: 'https://picsum.photos/60/60',
    level: { id: 1, name: 'VIP' },
    counts: {
        direct: 666, //直邀人数
        real_name: 600, //实名人数
        today: 66, //今天新增
        yesterday: 70, //昨天新增
        this_month: 888, //本月新增
        team_peoples: 9999 //团队总人数
    },
    teams: [] //团队
})
const a = 20
for(var i=0; i<a; i++) {
    data.value.teams.push(
        {
            id: 100 + i, //会员Id
            name: '陈小春', //姓名
            avatar: 'https://picsum.photos/50/50', //头像
            level: {id: 1, name: '用户'}, //级别
            name_status: 1, //是否已实名 0未实名， 1已实名
            team_peoples: 100 + i //团队人数
        }
    )
}

function getData(id) {
    console.log(id)
}
onMounted(() => {
    getData()
    setTimeout(() => {
        readyList.value = true
    }, 2000);
    
})

//二维码
const showCode = ref(false)
const ready = ref(false)
const qcode = ref('')
function genCode () {
    if (ready.value) {
        showCode.value = true
        return
    }

    QRCode.toDataURL('https://121.121cc.cn/attachment/lshd_zhaopinhign/1631347189.jpghttps://121.121cc.cn/attachment/lshd_zhaopinhign/1631347189.jpg', (err, url) => {
        if (err) throw err
        
        qcode.value = url
        ready.value = true
        showCode.value = true
    })
}

</script>
<style scoped>
* {
    margin:0;
    padding:0;
}
#team p {
    color: rgb(240, 240, 240);
}
.head-box {
    background:linear-gradient(to top,#00796B,#009687d0);
}
.head-box1 {
    background:linear-gradient(to top,#00968486,#009687d0);
}
.content {
    margin-top: -12px;
    padding: 15px;
}
#team #head {
    height:90px;
}
#head .rounded-circle {
    width: 60px;
    height: 60px;
}

#head .d-flex .title {
    font-weight:600;
    color:#ffffff;
}
#head .d-flex #contact {
    font-size:0.75em;
    color:#ced4da;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

#head #qrcode{
    cursor: pointer;
    border:1px solid #17a2b8 !important;
    background:#00796B !important;
    height:28px;
    border-radius:25px;
    line-height:1.8em;
    font-size:0.9rem;
    color:#ffffff !important;
    width: 80px;
    text-align: center;
    }

.wrap {
    z-index: 99999;
    position: fixed;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    text-align: center;
}

.wrap img {
    margin-top: 5em;
    width: 200px;
}

</style>
